export const API_GET_URL = '/v1/projects';
export const API_GET_URL_BY_REALM = '/v1/project-list/{realmId}';
export const API_VIEW_URL = '/v1/projects/{project}';
export const API_POST_URL = '/v1/projects';
export const API_UPDATE_URL = '/v1/projects/{project}';
export const API_DELETE_URL = '/v1/projects/{project}';
export const API_GET_PROJECT_VERIFICATION = '/v1/projects/{projectId}';
export const API_GET_URL_RETIRED_BY_FILTER = '/v1/user-list-by-realm-to-filter/{realmId}';
export const API_GET_METHODOLOGIES = '/v1/methodology-list';
export const API_GET_DEFAULT_INFO_ATTRIBUTES = '/v1/project-default-fields-info/{methodologyTypeId}';
export const API_POST_CREATE_API = '/v1/save-project-data-api';

export const REPORTING_API_GET_URL = '/v1/list-reporting-unit/{instanceId}/{isPublic}';
export const REPORTING_API_VIEW = '/v1/reporting-unit-detail/{reportingId}';

export const REPORTING_READ_DOC = '/v1/document/read/{id}/{brand}/{docType}';
export const REPORTING_PDF_URL = '/v1/assets-inventory/assets/{assetId}/pdf';
export const GENERATE_REPORT = '/v1/retirement/generate-report';

/** API list for create project VM Series */
export const API_GET_STEPS = '/v1/steps/{processFlowId}';
export const API_GET_INSTNCE_MEASRMNT_ATTR = '/v1/instance-measurement-attrs/{methodologyId}/{stepId}';
export const API_SAVE_PROJECT_INFO = '/v1/projects';
export const API_UPDATE_PROJECT_INFO = '/v1/update-project-info/{projectId}';

export const API_SAVE_PROJECT_DATA = '/v1/save-project-data';
export const API_POST_PROJECT_DOCUMENT = '/v1/document/document';

export const API_POST_PROJECT_DOWNLOAD_DOCUMENT = '/v1/document/download/{documentId}';
export const API_POST_PROJECT_DELETE_DOCUMENT = '/v1/document/delete/{documentId}';

export const API_GET_VERIFIER_COMPANIES = '/v1/verifiers-list/{methodologyId}';
export const API_POST_SEND_PROJECT_FOR_VERIFICATION = '/v1/request-approver/{projectId}';
export const API_UPDATE_PROJECT_NAME = '/v1/update-project-name/{projectId}';

export const API_ACCEPT_CREDITS = '/v1/accept-project-credits/{projectId}';
export const API_AWAITING_UPDATE = '/v1/awaiting-update/{projectId}';
export const API_CREATE_INSTANCE = '/v1/create-instance/{projectId}';
export const API_CREATE_MEASUREMENT = '/v1/create-measurement';

//canary
export const API_OCR_PDF_UPLOAD = 'v1/document/ocr-pdf-upload';
export const API_GET_OCR_TEXT = '/v1/get-ocr-values/{projectId}';

// Verifier side api's
export const API_PUT_CLAIM_PROJECT = '/v1/project-claim/{projectId}';
export const API_PUT_RESOLVE_COMMENT = '/v1/project-comments/resolve/{projectId}/{id}';
export const API_PROJECT_CREATORS = '/v1/realm-project-creators/{projectId}';

// verify measurement
export const API_VERIFY_PROJECT_MEASUREMENT = '/v1/verify-project-measurement/{projectId}/{measurementId}';
export const API_UN_VERIFY_PROJECT_MEASUREMENT = '/v1/un-verify-project-measurement/{projectId}/{measurementId}';
export const API_VERIFY_PROJECT = '/v1/verify-project/{projectId}';

// Comments store for both verifier and creators
export const API_CREATE_COMMENT = '/v1/project-comments/{projectId}';

export const PROJECT_VERIFIERS = '/v1/project-verifier-details/{projectId}';
